import React from 'react'
import { Section, SectionBackground, SectionTitle } from '../components/Section'

const ContactSection = () => (
    <Section className="address">
        <SectionBackground></SectionBackground>
        <div className="foreground">
            <SectionTitle>Unde ne găsești</SectionTitle>
            <p>Te invităm alături de noi, nu doar aici, în spațiul virtual ci și în cadrul întâlnirilor noastre. Vrem să te cunoaștem mai bine, să ne închinăm împreună, să studiem Cuvântul lui Dumnezeu, și să ne lăsăm transformați de El în gândirea și trăirea noastră.</p>
            <div className="row">
                <div className="left">
                    <SectionTitle>Programele bisericii</SectionTitle>
                    <table>
                        <tbody>
                            <tr>
                                <td><strong>Duminică</strong></td>
                                <td>
                                    10:00-12:00<br />
                                    18:00-19:30
                                            </td>
                            </tr>
                            <tr>
                                <td><strong>Luni</strong></td>
                                <td>
                                    19:00-21:00 - Întâlnirea liceenilor
                                            </td>
                            </tr>
                            <tr>
                                <td><strong>Marți</strong></td>
                                <td>
                                    19:00-21:00 - Întâlnirea 19+
                                            </td>
                            </tr>
                            <tr>
                                <td><strong>Miercuri</strong></td>
                                <td>19:00-20:30 - Rugăciune</td>
                            </tr>
                            <tr>
                                <td><strong>Joi</strong></td>
                                <td>19:00-21:00 - Grupele de studiu biblic</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="right">
                    <SectionTitle>Adresa</SectionTitle>
                    <p><i className="fa fa-map-marker fa-lg" /> str. Dr. Ioan Rațiu nr. 13 A</p>
                    <p><i className="fa fa-map-o fa-lg" /> Alba Iulia, Romania</p>
                </div>
            </div>
        </div>
    </Section>
)

export default ContactSection